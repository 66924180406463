@use "../config" as *;
.waf-fixtures {
    @extend %p-4;
    .waf-head {
        @extend %pb-6;
        .head-wrap {
            flex-direction: column;
            align-items: flex-start;
            @extend %gap-4;
        }
        .title {
            @extend %heading-6;
        }
    }
    .tab-container {
        &-head {
            border-bottom: .1rem solid var(--neutral-200);
            @extend %mb-4;
            @extend %pb-4;
            .date-time {
                &::after {
                    content: unset;
                }
            }
            .date {
                @extend %neutral-900;
                @extend %capitalize;
                @extend %font-16-pb;
            }
            .time {
                @extend %d-none;
            }
        }
    }
    .card {
        &-head {
            @extend %pb-2;
            @extend %flex-n-c;
        }
        &-content {
            border-block: .1rem solid var(--neutral-50);
            @extend %py-3;
        }
        &-venue {
            @extend %d-none;
        }
        &-number {
            @extend %pr-1;
            @extend %neutral-500;
            @extend %font-12-pm;
            .text {
                // @extend %neutral-500;
                // @extend %font-12-pm;
                @include truncate-text(1);
            }
        }
        &-item {
            min-height: 16rem;
            @extend %relative;
            @extend %mb-4;
            @extend %p-4;
            @extend %half-radius;
            @extend %pure-white-900-bg;
            &.recent {
                .status {
                    @extend %primary-900;
                }
            }
        }
        &-footer {
            @extend %pt-2;
            &-text {
                @include truncate-text(2);
                @extend %font-12-pm;
                @extend %neutral-400;
            }
            .btn-scorecard {
                @extend %w-100;
                @extend %h-100;
                @extend %pos-tl;
                .btn-text {
                    @extend %font-0;
                }
            }
        }
    }
    .live {
        .status {
            gap: .5rem;
            @extend %flex-n-c;
            &::before {
                content: '';
                width: 1.5rem;
                height: 1.5rem;
                @extend %flex;
                @include background(null, "svg/live.svg", center / contain no-repeat);
            }
        }
    }
    .recent {
        .team-won {
            .team-data {
                &:last-child {
                    .score {
                        @extend %neutral-800;
                    }
                }
            }
        }
    }
    .upcoming {
        .match-time {
            right: var(--space-0);
            @extend %pos-y-center;
            @extend %flex;
            .time-text {
                @extend %font-14-pb;
                @extend %neutral-800;
            }
        }
        .team-name {
            &.full-name {
                width: auto;
            }
        }
    }
    .status {
        order: 1;
        flex-shrink: 0;
        @extend %font-10-pm;
        @extend %neutral-800;
        @extend %uppercase;
        @extend %ml-auto;
    }
    .score {
        @extend %neutral-400;
        @extend %font-12-pb;
    }
    .overs {
        @extend %neutral-400;
        @extend %font-10-pm;
    }
    .team {
        height: 2.5rem;
        @extend %flex-sb-c;
        &-data-wrap {
            @extend %gap-1;
            @extend %flex-n-c;
        }
        &-data {
            line-height: 1.4rem;
            @extend %gap-1;
            @extend %flex-n-c;
        }
        &-wrapper {
            @extend %relative;
            @extend %gap-1;
            @extend %flex-column;
        }
        &-info {
            @extend %gap-2;
            @extend %flex-n-c;
        }
        &-name {
            @extend %font-10-pb;
            @extend %uppercase;
            @extend %neutral-800;
            &.full-name {
                width: 12rem;
                @include truncate-text(2);
            }
        }
        &-image {
            border: .1rem solid var(--neutral-50);
            width: 2.5rem;
            height: 1.5rem;
            flex-shrink: 0;
            @extend %neutral-50-bg;
        }
        &-current {
            .team-data {
                &:last-child {
                    .score {
                        @extend %neutral-800;
                    }
                }
            }
        }
        &-score {
            @extend %text-right;
        }
    }
    .date-time {
        order: -1;
        flex-shrink: 0;
        @extend %mr-2;
        @extend %relative;
        &::after {
            content: '';
            width: .1rem;
            right: var(--space-1-neg);
            @extend %pos-y-center;
            @extend %h-70;
            @extend %neutral-500-bg;
        }
        .meta {
            @extend %neutral-500;
            @extend %font-12-pm;
        }
    }
    .countdown-wrapper,
    .short-name,
    .run-rate,
    .match-time,
    .btn-ticket {
        @extend %d-none;
    }
    .no-data-wrap {
        @extend %mt-5;
        @extend %flex-c-c;
        @extend %w-100;
        .title {
            @extend %half-radius;
            @extend %py-2;
            @extend %px-3;
            @extend %uppercase;
            @extend %neutral-800;
            @extend %primary-300-bg;
            @extend %font-14-pb;
        }
    }
    &.waf-shimmer {
        .status {
            @extend %font-0;
            @include shimmer(4.2rem, 2rem, var(--full-radius));
            &:before {
                content: unset;
            }
        }
        .team-name {
            @extend %font-0;
            @include shimmer(12rem, 1.2rem, var(--full-radius));
        }
        .card-number {
            .text {
                @extend %font-0;
                @include shimmer(10rem, 2rem, var(--full-radius));
            }
        }
        .date-time {
            @include shimmer(7.5rem, 2rem, var(--full-radius));
            .meta {
                @extend %font-0;
            }
        }
        .card-footer-text {
            @extend %font-0;
            @include shimmer(100%, 1.4rem, var(--full-radius));
        }
        .team-image {
            @extend %font-0;
            @include shimmer(2.5rem, 1.5rem, null);
            .team-logo {
                width: 0;
                height: 0;
            }
        }
        .upcoming {
            .match-time {
                .time-text {
                    @extend %font-0;
                    @include shimmer(3rem, 1.6rem, var(--full-radius));
                }
            }
        }
        .team-data {
            .score,.overs {
                @extend %font-0;
            }
            @include shimmer(3rem, 1.5rem, var(--full-radius));
        }
    }
}
@include mq(col-md) {
    .waf-fixtures {
        padding-inline: var(--space-0);
        .waf-head {
            padding-bottom: var(--space-2);
            .title {
                font-size: 2.4rem;
                padding-bottom: var(--space-6);
            }
            .head-wrap {
                gap: var(--space-0);
            }
        }
        .tabs {
            padding-right: 16rem;
            border-radius: var(--half-radius) 0 0 var(--half-radius)
        }
        .tab-container {
            &-body {
                display: flex;
                flex-wrap: wrap;
                gap: var(--space-3);
            }
            &-head {
                padding-top: var(--space-4);
            }
        }
        .card {
            &-item {
                width: calc(50% - .6rem);
                margin-bottom: 0;
            }
        }
    }
    .secondary-menu-active {
        .waf-fixtures {
            .tabs {
                padding-right: 0;
            }
        }
    }
}
@include mq(col-lg) {
    .waf-fixtures {
        .tab-container-body {
            gap: var(--space-4);
        }
        .score {
            font-size: 1.4rem;
        }
        .overs {
            font-size: 1.2rem;
        }
        .card-item {
            width: calc(50% - var(--space-2));
        }
    }
}